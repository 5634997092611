import { useHRApi } from "@/api/useHRApi";
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";

export const fetchPartnerLevels = async (contract, tokens) => {
  if (!tokens.length) return {};
  const wallet = useWeb3Store().collectionWallet;
  const data = await useHRApi({ throw: true }).exec({
    url: "/v1/bap/partner-levels",
    params: {
      wallet_address: wallet,
      contract_address: contract,
      token_ids: tokens,
      version: Config.mode === "staging" ? 2 : undefined,
    },
  });
  return data.levels;
};
