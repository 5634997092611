import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { TokenType } from "@/config/token-types";
import { useWeb3Store } from "@/store/web3";
import moment from "moment";

export const fetchApes = async () => {
  const [apes, lootBoxData] = await Promise.all([
    useApesStorageApi({ throw: true }).exec({
      url: "/wallet/apes",
      params: {
        wallet: useWeb3Store().collectionWallet,
        network: Config.network.name,
      },
    }),
    useApesStorageApi().exec({
      url: "/lootbox/wallet-price",
      params: {
        wallet: useWeb3Store().collectionWallet,
      },
    }),
  ]);

  const resolvers = apes.map(async (ape) => {
    const traits = {};

    let guild = "";
    ape.attributes?.forEach((attr) => {
      if (attr.trait_type == "Guild") {
        guild = attr.value;
      }
      traits[attr.trait_type] = attr.value;
    });
    const freeSpinsCount =
      ape.free_spins?.filter((spin) => spin.used === false).length || 0;
    const boxData = lootBoxData[ape.token_id] || {};
    const lastBoxOpened = boxData.lastBoxOpened * 1000;
    // 1 min per boost
    const minsToAdd = Math.max(boxData.booster * 1, 2);
    const openUntil = moment(lastBoxOpened).add(minsToAdd, "minutes").toDate();
    const lootBox = {
      ...boxData,
      lastBoxOpened,
      openUntil,
      booster: boxData.booster,
      maxBoost: boxData.maxBoost,
      lastBoxOpenedCount: boxData.lastBoxOpenedCount,
    };
    const powerPerHour = ape.power_per_hour;
    const fullPowerDate = moment().add(
      Math.ceil((100 - ape.power) / powerPerHour),
      "hours"
    );
    const powerInfo = {
      powerPerHour,
      fullPowerDate: fullPowerDate.toDate(),
    };
    const isGod = ape.type === 'God Ape'
    return {
      ...ape,
      type: isGod ? TokenType.GOD_APE : TokenType.APE,
      isGod,
      image: ape.image
        ? ape.image.replace("original", "w=512")
        : require("@/assets/img/Bulls/bull-placeholder.gif"),
      guild,
      freeSpinsCount,
      traits,
      lootBox,
      originalId: ape.token_id_original,
      id: ape.token_id,
      powerInfo,
    };
  });
  return await Promise.all(resolvers);
};
