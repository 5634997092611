import { TokensContracts } from "@/config/tokens-contracts";

export const getTokenTypeByContract = (contractAddress) => {
  const tokenType = Object.keys(TokensContracts).find((tokenType) => {
    return (
      TokensContracts[tokenType].contractAddress.toLowerCase() ===
      contractAddress.toLowerCase()
    );
  });
  return tokenType;
};
