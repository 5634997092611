import { TokenType } from "@/config/token-types";

export const getGodApeBonus = (token) => {
  const tokenType = token.type;
  if (tokenType === TokenType.GENESIS) {
    return 100
  }
  if (tokenType === TokenType.GOD) {
    return 150
  }
  if (tokenType === TokenType.PARTNER) {
    return token.harvestInfo.harvestPerDay * 5;
  }
  if (
    [TokenType.BOTBOG, TokenType.BOTBEVO, TokenType.BOTBBEARS].includes(
      tokenType
    )
  ) {
    if (token.isInSet) {
      const BOTBSetBonus = {
        1: 10,
        2: 15,
        3: 25,
      };
      return BOTBSetBonus[token.harvestInfo.level];
    }
    return token.harvestInfo.harvestPerDay * 5;
  }
  if (tokenType === TokenType.TEEN) {
    if (token.isResurrected) return 35;
    if (token.hasActiveMethMachine) return 0;
    return token.harvestInfo.harvestPerDay * 5;
  }
  return 0;
};
