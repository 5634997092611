<template>
  <component :is="layout">
    <router-view />
  </component>
  <AToastContainer />
  <AModal />
  <ScrollTopArrow />
  <AActionBar />
</template>

<script>

import ScrollTopArrow from "./components/ScrollTopArrow.vue";

import { init as initWallet } from "./web3/wallet-connect"
import AToastContainer from "./components/AToast/AToastContainer.vue";
import AModal from "./components/Modal/AModal.vue";
import AActionBar from "./components/AActionBar/AActionBar.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import DefaultLayout from "@/layouts/default.vue"
import EmptyLayout from "@/layouts/empty.vue"

export default {
  components: {
    AActionBar,
    AModal,
    AToastContainer,
    ScrollTopArrow,

  },
  setup() {
    initWallet();
    const layouts = {
      default: DefaultLayout,
      empty: EmptyLayout
    }
    const route = useRoute()
    const layout = computed(() => {
      return layouts[route.meta?.layout] || DefaultLayout
    })

    return { layout }
  },


};
</script>
