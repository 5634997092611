import { ref } from "vue";

const isPlaying = ref(false);

const turnOn = () => {
  isPlaying.value = true;
};
const turnOff = () => {
  isPlaying.value = false;
};

const switchSound = () => {
  if (isPlaying.value) {
    turnOff();
  } else {
    turnOn();
  }
};
export const useAppSound = () => {
  return {
    switchSound,
    turnOn,
    turnOff,
    isPlaying,
  };
};
