import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useWeb3Store } from "@/store/web3";

export const fetchApeTraits = async () => {
  const data = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/wallet/traits",
    params: {
      wallet: useWeb3Store().walletAddress,
      filters: false,
    },
  });
  return data;
};
