import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useSentry } from "@/composables/useSentry";
import { useToast } from "@/composables/useToast";
import { TokenType } from "@/config/token-types";
import { fetchApes } from "@/functions/fetchCollection/fetchApes";
import { fetchApeTraits } from "@/functions/fetchCollection/fetchApeTraits";
import { fetchCollection } from "@/functions/fetchCollection/fetchCollection";
import { captureError } from "@/helpers/captureError";
import { defineStore } from "pinia";
import { useMethBankStore } from "./meth-bank";
import { useWeb3Store } from "./web3";
import tempPartnersData from "../config/temp-collection.json";
import axios from "axios";
import { web3 } from "@/web3";
import { fetchPartnerTokens } from "@/functions/fetchCollection/fetchPartners";
import { fetchUtilities } from "@/functions/fetchCollection/fetchUtilities";
import { ACHIEVEMENT_FLAG_ID } from "@/helpers/achievement-flag";
import { getMethGeneration } from "@/helpers/get-meth-generation";
import { getApeBonus } from "@/helpers/get-ape-bonus";

export const useWalletCollectionStore = defineStore("wallet-collection", {
  state: () => ({
    genesis: [],
    gods: [],
    teens: [],
    orbs: [],
    incubators: [],
    methMachines: [],
    teenResurrections: [],
    breedingReplenishments: [],
    botbOG: [],
    botbEVO: [],
    botbBears: [],
    botbSpecial: [],
    partners: {},
    allApes: [],
    apeTraits: [],
    yellowIncubators: [],
    creatorPassports: [],
    apeTickets: [],
    forgeBlocks: [],
    forgeBlocksBalance: {},
    isLoading: true,
  }),
  getters: {
    apes() {
      return this.allApes.filter((ape) => !ape.isGod);
    },
    godApes() {
      return this.allApes.filter((ape) => ape.isGod);
    },
    achievementFlagsBalance() {
      const item = this.apeTraits.find(
        (trait) => trait.id === ACHIEVEMENT_FLAG_ID
      );
      const offChain = item?.offChain || 0;
      const onChain = item?.balance || 0;
      return { offChain, onChain, all: offChain + onChain };
    },
    apeAttachmentsMap() {
      const attachmentMap = {};
      this.allApes.forEach((ape) => {
        if (!ape.attachment) return;
        const { contract_address, token_id } = ape.attachment;
        attachmentMap[`${contract_address}_${token_id}`] = ape.id;
      });
      return attachmentMap;
    },
    botbSetsIds() {
      const ids = this.botbOG
        .filter((token) => token.isInSet)
        .map((token) => token.id);

      return ids;
    },
    botbSetsTokens() {
      return this.botbSetsIds.map((id) => {
        const OG = this.botbOG.find((token) => token.id == id);
        const Bears = this.botbBears.find((token) => token.id == id);
        const EVO = this.botbEVO.find((token) => token.id == id);
        return {
          id,
          OG,
          Bears,
          EVO,
        };
      });
    },
    resurrectedTeens() {
      return this.teens.filter((teen) => teen.isResurrected);
    },
    onChainMethMachines() {
      return this.methMachines.filter((item) => item.onChainCount > 0);
    },
    // Devide tokens by smart contract address instead of symbol as a key.
    // Symbols can be duplicated for tokens with different SC addresses in `partners` array
    partnersSorted() {
      const partnersTokens = {};
      const partners = this.partners;
      Object.keys(partners).forEach((partnerName) => {
        partners[partnerName].forEach((partnerItem) => {
          const partnerAddress = web3.utils.toChecksumAddress(
            partnerItem.token_address
          );
          if (!partnersTokens[partnerAddress])
            partnersTokens[partnerAddress] = [];
          partnersTokens[partnerAddress].push(partnerItem);
        });
      });
      return partnersTokens;
    },
    dailyMethGeneration() {
      const genesisMeth = this.genesis.reduce(
        (sum, item) => sum + getMethGeneration(item) + getApeBonus(item),
        0
      );
      const godsMeth = this.gods.reduce(
        (sum, item) => sum + getMethGeneration(item) + getApeBonus(item),
        0
      );
      const teenMeth = this.teens
        .filter((teen) => teen.isResurrected && !teen.isBurned)
        .reduce(
          (sum, item) => sum + getMethGeneration(item) + getApeBonus(item),
          0
        );
      return genesisMeth + godsMeth + teenMeth;
    },
    claimableMeth() {
      let totalMeth = 0;
      this.genesis.forEach((token) => {
        totalMeth += token.claimableMeth;
      });
      this.gods.forEach((token) => {
        totalMeth += token.claimableMeth;
      });
      this.teens.forEach((teen) => {
        if (!teen.isResurrected) return;
        totalMeth += teen.claimableMeth;
      });
      return totalMeth;
    },
    utilities() {
      return [
        ...this.incubators,
        ...this.orbs,
        ...this.breedingReplenishments,
        ...this.teenResurrections,
        ...this.methMachines,
        ...this.forgeBlocks,
        ...this.yellowIncubators,
        ...this.creatorPassports,
        ...this.apeTickets,
      ];
    },
    allTokens() {
      return {
        [TokenType.GENESIS]: this.genesis,
        [TokenType.GOD]: this.gods,
        [TokenType.TEEN]: this.teens,
        [TokenType.MERGER_ORB]: this.orbs,
        [TokenType.INCUBATOR]: this.incubators,
        [TokenType.METH_MACHINE]: this.methMachines,
        [TokenType.TEEN_RESURRECTION]: this.teenResurrections,
        [TokenType.BREEDING_REPLENISHMENT]: this.breedingReplenishments,
        [TokenType.BOTBOG]: this.botbOG,
        [TokenType.BOTBEVO]: this.botbEVO,
        [TokenType.BOTBBEARS]: this.botbBears,
        [TokenType.APE]: this.apes,
        [TokenType.GOD_APE]: this.godApes,
        [TokenType.PARTNER]: this.partners,
        [TokenType.BOTB_SPECIAL]: this.botbSpecial,
        [TokenType.YELLOW_INCUBATOR]: this.yellowIncubators,
        [TokenType.CREATOR_PASSPORT]: this.creatorPassports,
        [TokenType.APE_TICKET]: this.apeTickets,
      };
    },
    botbTokens() {
      return [this.botbOG, this.botbEVO, this.botbBears];
    },
    totalClaimableMeth() {
      return this.claimableMeth;
    },
    isCollectionEmpty() {
      return (
        !this.genesis.length &&
        !this.teens.length &&
        !this.gods.length &&
        !this.orbs.length &&
        !this.incubators.length &&
        !this.methMachines.length &&
        !this.teenResurrections.length &&
        !this.breedingReplenishments.length &&
        !this.botbOG.length &&
        !this.botbEVO.length &&
        !this.botbBears.length &&
        !this.botbSpecial.length &&
        !this.apes.length &&
        !this.godApes.length &&
        !this.yellowIncubators.length &&
        !this.creatorPassports.length &&
        !this.apeTickets.length &&
        !this.forgeBlocks.length &&
        !Object.keys(this.partners).length
      );
    },

    collectionWallet() {
      return useWeb3Store().collectionWallet;
    },
  },
  actions: {
    async fetchApeTraits() {
      try {
        this.apeTraits = await fetchApeTraits();
      } catch (err) {
        captureError(err);
      }
    },
    async fetchForgeBlocks() {
      const {
        forgeBlocksBalance,
        methMachines,
        breedingReplenishments,
        teenResurrections,
      } = await fetchUtilities({});
      this.forgeBlocksBalance = forgeBlocksBalance;
      this.methMachines = methMachines;
      this.breedingReplenishments = breedingReplenishments;
      this.teenResurrections = teenResurrections;
    },
    async fetchApes() {
      try {
        this.allApes = await fetchApes();
      } catch (err) {
        captureError(err);
      }
    },
    async fetchPartners() {
      try {
        this.partners = {};
        this.partners = await fetchPartnerTokens();
      } catch (err) {
        console.error("Error loading partners", err);
      }
    },
    async fetchCollection(silent) {
      if (!silent) {
        this.isLoading = true;
      }
      try {
        const {
          teens,
          partners,
          genesis,
          gods,
          botb,
          teenResurrections,
          breedingReplenishments,
          incubators,
          orbs,
          yellowIncubators,
          creatorPassports,
          apeTickets,
          forgeBlocksBalance,
          forgeBlocks,
          methMachines,
          apes,
        } = await fetchCollection();
        this.teens = teens;
        this.genesis = genesis;
        this.gods = gods;
        this.botbEVO = botb.botbEVO;
        this.botbOG = botb.botbOG;
        this.botbBears = botb.botbBears;
        this.botbSpecial = botb.botbSpecial;
        this.partners = partners;
        this.incubators = incubators;
        this.orbs = orbs;
        this.forgeBlocksBalance = forgeBlocksBalance;
        this.forgeBlocks = forgeBlocks;
        this.teenResurrections = teenResurrections;
        this.methMachines = methMachines;
        this.breedingReplenishments = breedingReplenishments;
        this.allApes = apes;
        this.yellowIncubators = yellowIncubators;
        this.creatorPassports = creatorPassports;
        this.apeTickets = apeTickets;

        // FOR TEST
        // this.teens = teens.slice(0, 3);
        // this.genesis = [];
        // this.gods = [];
        // this.apes = [];

        // this.botbOG = [];

        // const items = tempPartnersData;
        // let keys = Object.keys(tempPartnersData);

        // for (let index = 0; index < keys.length; index++) {
        //   const tokens = items[keys[index]];
        //   for (let i = 0; i < tokens.length; i++) {
        //     const el = tokens[i];

        //     let extraInfo = el.metadata;
        //     if (!extraInfo || !extraInfo.image) {
        //       try {
        //         const { data } = await axios.get(el.uri);
        //         extraInfo = {
        //           ...data,
        //           image: data.image ? data.image : data.image_url,
        //           traits: data.traits || data.attributes,
        //         };
        //       } catch (error) {
        //         extraInfo = {};
        //         console.log(`Error getting partner metadata`, el.symbol, el.id);
        //       }
        //     }

        //     if (extraInfo && extraInfo.image) {
        //       let isIPFS = extraInfo.image.split(":/").includes("ipfs");

        //       if (isIPFS) {
        //         let url = extraInfo.image.split(":/");
        //         url = "https://botbipfs.infura-ipfs.io/ipfs" + url[1];
        //         extraInfo.image = url;
        //       }
        //     }

        //     extraInfo.harvestInfo = el.harvestInfo || {
        //       level: 1,
        //       harvestPerDay: 1,
        //       isHarvestedToday: false,
        //       daysLeft: 4,
        //     };

        //     tokens[i] = {
        //       ...el,
        //       ...extraInfo,
        //       id: el.id,
        //       type: TokenType.PARTNER,
        //     };
        //   }
        // }
        // this.partners = items;
        // END FOR TEST

        useSentry().Sentry.setTag(
          "bap_asset_count",
          Object.keys(this.allTokens).reduce((sum, key) => {
            return (sum +=
              key !== TokenType.PARTNER ? this.allTokens[key].length || 0 : 0);
          }, 0)
        );
        useSentry().Sentry.setTag(
          "community_asset_count",
          Object.keys(this.partners).reduce((sum, key) => {
            return (sum += this.partners[key].length || 0);
          }, 0)
        );
      } catch (err) {
        useToast().error({
          text: "Couldn’t load your collection. Don’t refresh immediately but try again in a few minutes.",
        });
        captureError(err);
      }
      this.isLoading = false;
    },
  },
});
