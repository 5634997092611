export const errorHandler = (err) => {
  if (err) {
    const response = err.response;
    if (response?.data && response.data.error) {
      return response.data.error;
    }
    if (response?.status === 422) {
      // return i18nT('apiErrors.invalid')
      return "Invalid Request";
    }
    if (response?.status === 400) {
      return "Bad Request";
    }
    if (response?.status === 500) {
      // return i18nT('apiErrors.serverError')
      return "Server Error";
    }
  }
  return "Something went wrong";
};
