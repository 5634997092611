<template>
  <div class="layout-default">
    <AHeader />
    <div class="page-wrapper">
      <slot />
    </div>
    <AFooter />
  </div>
</template>
<script>import { defineComponent } from "vue-demi";
import AFooter from "@/components/AFooter.vue"
import AHeader from "@/components/AHeader.vue"
export default defineComponent({
    components: { AFooter, AHeader },
})
</script>
<style lang="scss">
.layout-default {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
    .page-wrapper {
        flex: 1;
        box-sizing: border-box;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .footer {
        margin-top: auto;
    }
}
</style>