import { TokenType } from "../token-types";
import Web3 from "web3";
export const TokensContractsSepolia = {
  [TokenType.GENESIS]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0xD5dba6d7BfFC30b0c2ef0b70e1Cb3339E741c853"
    ),
    name: "Bull",
  },
  [TokenType.GOD]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0xD5dba6d7BfFC30b0c2ef0b70e1Cb3339E741c853"
    ),
    name: "God",
  },
  [TokenType.TEEN]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x4Bbc188951aF51977BD39eB7A02583fB09dc5DcB"
    ),
    name: "Teen",
  },
  [TokenType.APE]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0xc9EefA5329405A46fB4066964175Eb0591D078Dd"
    ),
    name: "Ape",
  },
  [TokenType.GOD_APE]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0xc9EefA5329405A46fB4066964175Eb0591D078Dd"
    ),
    name: "God Ape",
  },
  //   IT'S STILL MAINNET
  [TokenType.BOTBOG]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x3a8778A58993bA4B941f85684D74750043A4bB5f"
    ),
    name: "BOTB OG",
  },
  //   IT'S STILL MAINNET
  [TokenType.BOTBBEARS]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x02AA731631c6D7F8241d74F906f5b51724Ab98F8"
    ),
    name: "BOTB BEARS",
  },
  //   IT'S STILL MAINNET
  [TokenType.BOTBEVO]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x6069a6C32cf691F5982FEbAe4fAf8a6f3AB2F0F6"
    ),
    name: "BOTB EVO",
  },
};
