import { createApp } from "vue";
import router from "./router";
import { createPinia } from "pinia";
import "moment-timezone";
import App from "./App.vue";

import vClickOutside from "click-outside-vue3";
import FloatingVue from "floating-vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import "floating-vue/dist/style.css";
FloatingVue.options.themes.tooltip.delay.show = 50;

import Hotjar from "vue-hotjar";

import { useSentryInit } from "./libs/sentry";
import { useGTMInit } from "./libs/gtm";

import "./assets/scss/styles.scss";
import "./assets/scss/responsive.scss";
import { useSoundDirective } from "./libs/directives/sound-directive";

const pinia = createPinia();
const app = createApp(App);
useSentryInit(app, router);
app.use(pinia);
app.use(router);

useGTMInit(app, router);
useSoundDirective(app);

app.use(vClickOutside);
app.use(FloatingVue);
app.use(Hotjar, {
  id: "3263590",
  isProduction: process.env.VUE_APP_ENV === "production",
});
app.use(VueQueryPlugin);

app.mount("#app");
