import { defineStore } from "pinia";
import { networksInfo } from "../config/networks";
import { useInfoReaderContract, useUtilitiesContract } from "../web3/contracts";
import { Config } from "@/config";
import { useBapApi } from "@/api/useBapApi";
import { useRoute, useRouter } from "vue-router";
import { web3 } from "@/web3";
import { useWalletCollectionStore } from "./wallet-collection";
import { useMethBankStore } from "./meth-bank";
import { useStorageApi } from "@/api/useStorageApi";
import { useSentry } from "@/composables/useSentry";
import { captureError } from "@/helpers/captureError";
export const useWeb3Store = defineStore("web3", {
  state: () => {
    return {
      walletAddress: "",
      balance: 0,
      meth: 0,
      walletType: "",
      safeClaim: {
        isController: false,
        sourceWallet: "",
        controller: "",
        confirmed: false,
      },
      network: {
        name: undefined,
        chainId: undefined,
        symbol: undefined,
        scan: undefined,
      },
      emailSubmited: false,
      mergerOrbsPurchasedCount: 0,
    };
  },
  getters: {
    collectionWallet() {
      return this.safeClaim.isController
        ? this.safeClaim.sourceWallet
        : this.walletAddress;
    },
    canBeController() {
      return this.safeClaim.confirmed;
    },
    isWrongNetwork() {
      return this.network.chainId !== Config.network.chainId;
    },
    isConnected() {
      return !!this.walletAddress;
    },
    chainId() {
      return this.network.chainId;
    },
  },
  actions: {
    async updateEmailSubmited() {
      const data = await useStorageApi({
        toast: { error: true },
      }).exec({
        url: "/mail/list/" + this.walletAddress,
      });
      if (!data) return;
      this.emailSubmited = data.listed;
    },
    async updateNetwork() {
      const chainId = await web3.eth.getChainId();
      const networkInfo = networksInfo.find(
        (network) => network.chainId == chainId
      );
      if (networkInfo) {
        this.network = networkInfo;
      } else {
        this.network = {
          name: "",
          chainId,
          symbol: "?",
          scan: "",
        };
      }
    },
    async updateMethBalance() {
      const contract = useInfoReaderContract();
      const data = await contract.methods
        .walletBalances(this.walletAddress)
        .call();
      const meth = data.meth || 0;
      this.meth = parseInt(meth);
    },
    async updateBalance() {
      try {
        const balanceBig = await web3.eth.getBalance(this.walletAddress);
        this.balance = Number(web3.utils.fromWei(balanceBig));
      } catch (err) {
        console.log(err);
      }
    },
    async checkSafeClaim() {
      try {
        const { info: controllerInfo } = await useBapApi({ throw: true }).exec({
          url: "/safeclaim/info",
          method: "GET",
          params: {
            type: "controller",
            address: this.walletAddress,
          },
        });
        this.safeClaim = {
          sourceWallet: controllerInfo.source,
          controller: controllerInfo.controller,
          confirmed: controllerInfo.confirmed,
          isController: !!this.safeClaim.isController,
        };
      } catch (err) {
        captureError(err);
      }
    },
    switchSafeClaim() {
      if (this.safeClaim.isController) {
        this.safeClaim.isController = false;
      } else {
        this.safeClaim.isController = true;
      }
      // To make sure nothing cached programmatically
      useWalletCollectionStore().$reset();
      useWalletCollectionStore().fetchCollection();
      useMethBankStore().fetchMethBank();
    },
    setWalletAddress(wallet) {
      this.walletAddress = web3.utils.toChecksumAddress(wallet);
      this.safeClaim.isController = false;
    },
    async fetchMergerOrbPurchased() {
      try {
        const contract = useUtilitiesContract();
        const purchasedCount = await contract.methods
          .mergerOrbsPurchased()
          .call();
        this.mergerOrbsPurchasedCount = +purchasedCount;
      } catch (err) {
        captureError(err);
      }
    },
    async update() {
      await Promise.all([
        this.updateBalance(),
        this.updateMethBalance(),
        useMethBankStore().fetchMethBank(),
        this.updateNetwork(),
        this.checkSafeClaim(),
        this.updateEmailSubmited(),
      ]);
      useSentry().initUser();
    },
  },
});
