import { TokenType } from "@/config/token-types";
import { TokensContracts } from "@/config/tokens-contracts";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import Web3 from "web3";

export const getAttachedApe = (token) => {
  const tokenType = token.type
  let contractAddress = TokensContracts[tokenType]?.contractAddress
  if(tokenType === TokenType.PARTNER) {
    contractAddress = Web3.utils.toChecksumAddress(token.token_address)
  }
  const walletCollection = useWalletCollectionStore();
  const attachedApeId =
    walletCollection.apeAttachmentsMap[
      `${contractAddress}_${token.id}`
    ];
  if (!attachedApeId) return;
  return walletCollection.allApes.find((ape) => ape.id === attachedApeId);
};
