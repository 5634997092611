import { useWeb3Store } from "@/store/web3";
import * as Sentry from "@sentry/vue";
export const useSentry = () => {
  const captureException = (err, payload = {}) => {
    if (err?.code === 4001) {
      return;
    }
    if (err?.isAxiosError) {
      const response = err?.response || {};
      const request = {
        status: response.status,
        data: response.data,
        headers: response.headers,
        url: response.config?.url,
        method: response.config?.method,
      };
      console.error("[Axios Error]", request);
      // don't send axios errors
      // Sentry.withScope((scope) => {
      //   scope.setTag("request_error", true);
      //   scope.setContext("request", request);
      //   scope.setLevel("warning");
      //   Sentry.captureException(err);
      // });
    } else {
      Sentry.captureException(err, payload);
    }
  };
  const initUser = () => {
    const web3Store = useWeb3Store();
    Sentry.setUser({
      wallet_address: web3Store.walletAddress,
      isController: web3Store.safeClaim.isController,
      source: web3Store.safeClaim.sourceWallet,
    });
    Sentry.setTag("wallet_type", web3Store.walletType);
    Sentry.setTag("wallet_address", web3Store.walletAddress);
    Sentry.setTag("is_controller", web3Store.safeClaim.isController);
    if (web3Store.safeClaim.sourceWallet) {
      Sentry.setTag("source_wallet", web3Store.safeClaim.sourceWallet);
    }
  };
  return { captureException, initUser, Sentry };
};
