import { TokenType } from "@/config/token-types";
import { getAttachedApe } from "./get-attached-ape";
import { getGodApeBonus } from "./get-god-ape-bonus";
export const getApeBonus = (token) => {
  const apeToken = getAttachedApe(token)
  if(!apeToken) return 0
  if (apeToken?.type === TokenType.GOD_APE) return getGodApeBonus(token);
  const tokenType = token.type;
  if (tokenType === TokenType.GENESIS) {
    return 20;
  }
  if (tokenType === TokenType.PARTNER) {
    return token.harvestInfo.harvestPerDay;
  }
  if (
    [TokenType.BOTBOG, TokenType.BOTBEVO, TokenType.BOTBBEARS].includes(
      tokenType
    )
  ) {
    if (token.isInSet) {
      const BOTBSetBonus = {
        1: 2,
        2: 3,
        3: 5,
      };
      return BOTBSetBonus[token.harvestInfo.level];
    }
    return token.harvestInfo.harvestPerDay;
  }
  if (tokenType === TokenType.TEEN) {
    if (token.isResurrected) return 7;
    if (token.hasActiveMethMachine) return 0;
    return token.harvestInfo.harvestPerDay;
  }
  return 0;
};
