import { TokenType } from "../token-types";
import Web3 from "web3";
export const TokensContractsMainnet = {
  [TokenType.GENESIS]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x2cf6be9aac1c7630d5a23af88c28275c70eb8819"
    ),
    name: "Bull",
  },
  [TokenType.GOD]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x2cf6be9aac1c7630d5a23af88c28275c70eb8819"
    ),
    name: "God",
  },
  [TokenType.TEEN]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0xcf099445347f0f006c19b90f1c1933eb9413682b"
    ),
    name: "Teen",
  },
  [TokenType.APE]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x7f29b85834d6a2ba4bb1c64325686c6057b1b3c5"
    ),
    name: "Ape",
  },
  [TokenType.GOD_APE]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x7f29b85834d6a2ba4bb1c64325686c6057b1b3c5"
    ),
    name: "God Ape",
  },
  [TokenType.BOTBOG]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x3a8778A58993bA4B941f85684D74750043A4bB5f"
    ),
    name: "BOTB OG",
  },
  [TokenType.BOTBBEARS]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x02AA731631c6D7F8241d74F906f5b51724Ab98F8"
    ),
    name: "BOTB BEARS",

  },
  [TokenType.BOTBEVO]: {
    contractAddress: Web3.utils.toChecksumAddress(
      "0x6069a6C32cf691F5982FEbAe4fAf8a6f3AB2F0F6"
    ),
    name: "BOTB EVO",

  },
};
