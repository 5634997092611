import hoverAudio from "@/assets/audio/cluck.wav";
import clickAudio from "@/assets/audio/click_2.mp3";
import clickExcitedAudio from "@/assets/audio/click_excited.mp3";
import { useSound } from "@vueuse/sound";
import { deviceType } from "@/helpers/utils";
import { useAppSound } from "@/composables/useAppSound";
const elements = {};

const hoverSound = new Audio(hoverAudio);
const clickSound = new Audio(clickAudio);
const clickExcitedSound = new Audio(clickExcitedAudio);
clickSound.volume = 0.7
clickExcitedSound.volume = 0.2
const { isPlaying } = useAppSound();
class SoundElement {
  constructor(el, binding) {
    // enter, leave, click
    this.el = el;
    const defaultTriggers = ["click"];
    const devType = deviceType();
    let soundTriggers = Object.keys(binding.modifiers);
    soundTriggers = soundTriggers.length ? soundTriggers : defaultTriggers;

    
    const onMouseEnter = async () => {
      try {
        if (!isPlaying.value) return;
        hoverSound.pause();
        hoverSound.currentTime = 0;
        await hoverSound.play();
      } catch (err) {
        console.error(err);
      }
    };
    const onMouseClick = async () => {
      try {
        if (!isPlaying.value) return;
        const sound = soundTriggers.includes('excited') ? clickExcitedSound : clickSound
        sound.pause()
        sound.currentTime = 0;
        await sound.play()
      } catch (err) {
        console.error(err);
      }
    };
    // temp disable sounds
    
    // if (soundTriggers.includes("hover") && devType === "desktop") {
    //   el.addEventListener("mouseenter", onMouseEnter);
    // }
    // if (soundTriggers.includes("leave") && devType === "desktop") {
    //   el.addEventListener("mouseleave", onMouseEnter);
    // }
    // if (soundTriggers.includes("click")) {
    //   el.addEventListener("mousedown", onMouseClick);
    // }
  }
  destroy() {
    // console.log("destroy", this.el);
    // this.hoverSound.remove();
    // this.clickSound.remove();
  }
}
const soundDirective = {
  // called before bound element's attributes
  // or event listeners are applied
  created(el, binding, vnode, prevVnode) {
    // see below for details on arguments
  },
  // called right before the element is inserted into the DOM.
  beforeMount(el, binding, vnode, prevVnode) {},
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted(el, binding, vnode, prevVnode) {
    elements[el] = new SoundElement(el, binding);
  },
  // called before the parent component is updated
  beforeUpdate(el, binding, vnode, prevVnode) {},
  // called after the parent component and
  // all of its children have updated
  updated(el, binding, vnode, prevVnode) {},
  // called before the parent component is unmounted
  beforeUnmount(el, binding, vnode, prevVnode) {},
  // called when the parent component is unmounted
  unmounted(el, binding, vnode, prevVnode) {
    elements[el]?.destroy();
    elements[el] = null;
  },
};
export const useSoundDirective = (app) => {
  app.directive("sound", soundDirective);
};
