import { Config } from "@/config";
import { TokenType } from "@/config/token-types";
import { TokensContracts } from "@/config/tokens-contracts";
import Web3 from "web3";

export const getTokenContractAddress = (token) => {
  const type = token.type;

  if (TokensContracts[type]) return TokensContracts[type].contractAddress;
  if (type === TokenType.PARTNER) {
    return Web3.utils.toChecksumAddress(token.token_address);
  }
};
