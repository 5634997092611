import {
  useTeensContract,
  useAssetsInteractionsContract,
  useBapApesContract,
} from "@/web3/contracts";

// import { Config } from "./config";
export const guildIndexes = ["North", "South", "East", "West"];

export function shorten(string, head = 6, tail = 4) {
  if (!string.startsWith("0x")) {
    return string;
  }
  if (string && string.length) {
    const begin = string.slice(2, head + 2);
    const end = string.slice(-tail);
    return "0x" + `${begin} ... ${end}`.toUpperCase();
  }
}
export function deviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}
export function randomNum(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export function loadTeenTraits(metaUri) {
  return new Promise((resolve) => {
    return fetch(metaUri)
      .then((r) => r.json())
      .then((json) => {
        const traits = {};
        let guild = null;
        let locked = false;
        let name = null;
        json.attributes.forEach((attr) => {
          if (attr.trait_type == "Guild") {
            guild = attr.value;
          } else if (
            attr.trait_type == "Type" &&
            attr.value != "Teen" &&
            attr.value != "Resurrected Teen"
          ) {
            locked = true;
            name = attr.value;
          } else if (attr.trait_type != "Traits") {
            traits[attr.trait_type] = attr.value;
          }
        });
        resolve({
          type: "teen",
          isResurrected:
            json.attributes.findIndex(
              (attr) =>
                attr.trait_type == "Type" && attr.value == "Resurrected Teen"
            ) > -1,
          image: json.image,
          // image:
          //   Config.network.name === "sepolia"
          //     ? getTeenImageStaging(json.token_id)
          //     : json.image,
          traits,
          guild,
          locked,
          name,
        });
      })
      .catch(() => {
        resolve({
          type: "teen",
          image: require("../assets/img/Bulls/bull-placeholder.gif"),
          traits: [],
          guild: "North",
          locked: false,
          name: "",
        });
      });
  });
}

export function loadPartnersMetadata(metaUri) {
  return new Promise((resolve) => {
    return fetch(metaUri)
      .then((r) => r.json())
      .then((json) => {
        const traits = {};

        json.attributes.forEach((attr) => {
          traits[attr.trait_type] = attr.value;
        });

        resolve({
          image: json.image,
          traits,
        });
      })
      .catch(() => {
        resolve({
          type: "teen",
          image: require("../assets/img/Bulls/bull-placeholder.gif"),
          traits: [],
          guild: "North",
          locked: false,
          name: "",
        });
      });
  });
}

export async function isApprovedForTeens(walletAddress) {
  const teensContract = useTeensContract();
  const contractAddress = useAssetsInteractionsContract().options.address;
  return teensContract.methods
    .isApprovedForAll(walletAddress, contractAddress)
    .call();
}

export async function isApprovedForApes(walletAddress) {
  const apesContract = useBapApesContract();
  const contractAddress = useAssetsInteractionsContract().options.address;
  return apesContract.methods
    .isApprovedForAll(walletAddress, contractAddress)
    .call();
}

export async function isApprovedForUtilities(contracts, walletAddress) {
  const {
    utilities: { utilitiesContract },
    orchestrator: {
      orchestratorABI: { contractAddress },
    },
  } = contracts;
  return utilitiesContract.methods
    .isApprovedForAll(walletAddress, contractAddress)
    .call();
}

export async function isApprovedForGenesis(contracts, walletAddress) {
  const {
    genesis: { genesisContract },
    orchestrator: {
      orchestratorABI: { contractAddress },
    },
  } = contracts;
  return genesisContract.methods
    .isApprovedForAll(walletAddress, contractAddress)
    .call();
}
export function getTeenImageStaging(id) {
  let base = `https://labs.storage.mint.bullsandapesproject.com/teens/images/TeenBull.0`;
  if (id > 999) {
    return base + id + ".png";
  } else if (id > 99) {
    return base + "0" + id + ".png";
  } else if (id > 9) {
    return base + "00" + id + ".png";
  } else {
    return base + "000" + id + ".png";
  }
}
