import axios from 'axios'
import qs from 'qs'
const instance = axios.create({
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})
instance.interceptors.request.use(async (config) => {
  return config
})
export default instance
