<template>
  <div
    class="footer"
    :class="{ traitranks: isTraitRanks }"
  >
    <div class="footer-items">
      <a
        class="footer-item"
        target="_blank"
        href="https://www.bullsandapesproject.com/privacy-policy"
      >Privacy Policy</a>
      <a
        class="footer-item"
        target="_blank"
        href="https://storage.mint.bullsandapesproject.com/documents/terms_of_use.pdf"
      >Terms of Service</a>
      <a
        class="footer-item"
        target="_blank"
        href="https://storage.mint.bullsandapesproject.com/documents/license_agreement.pdf"
      >License Agreement</a>
    </div>
  </div>
</template>
<script>
export default {
    computed: {
        isTraitRanks() {
            return this.$route.name === 'traitranks'
        }
    }
}
</script>
