import * as contractsMainnet from "./contracts-mainnet";
import * as contractsStaging from "./contracts-sepolia";
import { Config } from "../../config";
import Contract from "web3-eth-contract";

import { useContract } from "../../composables/useContract";

Contract.setProvider(Config.network.rpcUrl);
const contracts =
  Config.network.name === "mainnet" ? contractsMainnet : contractsStaging;

const useMethContract = useContract(
  contracts.meth.abi,
  contracts.meth.contractAddress
);

const useGenesisContract = useContract(
  contracts.genesis.abi,
  contracts.genesis.contractAddress
);

const useUtilitiesContract = useContract(
  contracts.utilities.abi,
  contracts.utilities.contractAddress
);
const useOrchestratorContract = useContract(
  contracts.orchestrator.abi,
  contracts.orchestrator.contractAddress
);
const useTeensContract = useContract(
  contracts.teens.abi,
  contracts.teens.contractAddress
);
const useInfoReaderContract = useContract(
  contracts.infoReader.abi,
  contracts.infoReader.contractAddress
);
const useBapTicketsContract = useContract(
  contracts.bapTickets.abi,
  contracts.bapTickets.contractAddress
);

const useBapApesContract = useContract(
  contracts.bapApes.abi,
  contracts.bapApes.contractAddress
);

const usePortalPassContract = useContract(
  contracts.portalPass.abi,
  contracts.portalPass.contractAddress
);

const useLootBoxContract = useContract(
  contracts.lootbox.abi,
  contracts.lootbox.contractAddress
);
const useTraitsConstructorContract = useContract(
  contracts.traitsConstructor.abi,
  contracts.traitsConstructor.contractAddress
);
const useApeTraitsContract = useContract(
  contracts.apeTraits.abi,
  contracts.apeTraits.contractAddress
);
const useEquipOffChainContract = useContract(
  contracts.equipOffChain.abi,
  contracts.equipOffChain.contractAddress
);
const useUtilitiesInteractionsContract = useContract(
  contracts.utilitiesInteractions.abi,
  contracts.utilitiesInteractions.contractAddress
);
const useAssetsInteractionsContract = useContract(
  contracts.assetsInteractions.abi,
  contracts.assetsInteractions.contractAddress
);

export {
  contracts,
  useMethContract,
  useTeensContract,
  useInfoReaderContract,
  useBapTicketsContract,
  useGenesisContract,
  useUtilitiesContract,
  useOrchestratorContract,
  useBapApesContract,
  usePortalPassContract,
  useLootBoxContract,
  useTraitsConstructorContract,
  useApeTraitsContract,
  useEquipOffChainContract,
  useUtilitiesInteractionsContract,
  useAssetsInteractionsContract
};
