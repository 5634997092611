<template>
  <button
    :data-active="isOpened"
    class="BAP-header__nav__button"
    type="button"
  >
    {{ label }}
    <span class="BAP-header__nav--icon">
      <DownIcon />
    </span>
  </button>
  <ul
    :data-active="isOpened"
    class="BAP-header__nav--submenu"
  >
    <li
      v-for="item in items"
      :key="item.label"
      class="BAP-header__nav--submenu__item"
    >
      <a
        v-if="label.toLowerCase().trim() !== 'collection'"
        class="BAP-header__nav__button"
        :href="item.link"
      >{{ item.label }}</a>
      <a
        v-else
        class="BAP-header__nav__button"
        :href="item.link"
      >{{
        item.label
      }}</a>
    </li>
  </ul>
</template>

<script>
import DownIcon from "../assets/icons/chevron-down.svg?inline";

export default {
  components: {
    DownIcon,
  },
  props: {
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    isOpened: {
      type: Boolean,
    },
  },
};
</script>
