import { useWeb3Store } from "@/store/web3";
import { useInfoReaderContract } from "@/web3/contracts";
import { useBapApi } from "@/api/useBapApi";
import { fetchPartnerTokens } from "./fetchPartners";
import { fetchBotbTokens } from "./fetchBotb";
import { fetchTeens } from "./fetchTeens";
import { fetchGenesis } from "./fetchGenesis";
import { fetchUtilities } from "./fetchUtilities";
import { fetchApes } from "./fetchApes";

export const fetchCollection = async () => {
  const web3Store = useWeb3Store();
  let wallet = web3Store.collectionWallet;
  const infoReader = useInfoReaderContract();
  const bapTokens = await getCollection(wallet);

  let walletData = await infoReader.methods.walletBalances(wallet).call();
  let bullCount = walletData["bulls"];
  let teensCount = walletData["teens"];
  let utilitiesBalance = walletData["utilities"];

  let [
    { genesis, gods },
    teens,
    utilities,
    botb,
    partners,
    apes,
  ] = await Promise.all([
    fetchGenesis(bapTokens.genesis, bullCount),
    fetchTeens(bapTokens.teens, teensCount),
    fetchUtilities(utilitiesBalance),
    fetchBotbTokens(),
    fetchPartnerTokens(),
    fetchApes(),
  ]);
  // Update Teens
  teens = teens.map((teen) => {
    if (
      utilities.methMachines.findIndex((maker) => maker.guild === teen.guild) >
      -1
    ) {
      return { ...teen, hasActiveMethMachine: !teen.isResurrected };
    }

    return teen;
  });
 
  return { botb, genesis, gods, teens, partners, apes, ...utilities };
};

const getCollection = async function (wallet) {
  const response = await useBapApi({ throw: true }).exec({
    url: "/mint/tokens",
    method: "POST",
    data: {
      wallet,
    },
  });
  return response.result.nfts;
};
