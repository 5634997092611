<template>
  <transition name="t-action-bar">
    <div
      v-if="isActive"
      class="app-action-bar"
    >
      <div
        class="app-action-bar-text"
        v-html="text"
      />
      <div class="app-action-bar-actions">
        <a
          class="app-action-bar-action app-action-bar-action-cancel"
          :class="{ disabled: isLoading }"
          href="#"
          @click.prevent="cancel"
        >Cancel</a>
        <a
          class="app-action-bar-action app-action-bar-action-success"
          href="#"
          :class="{ loading: isLoading, disabled: !canApprove }"
          @click.prevent="approve"
        >
          {{ approveText }}
          <img
            v-if="isLoading"
            src="@/assets/img/spinner-clear.svg"
            class="loader"
          >
        </a>
      </div>
    </div>
  </transition>
</template> 
<script>import { computed, defineComponent } from "vue-demi";
import { useActionBar } from "./useActionBar";

export default defineComponent({
  setup() {
    const { isActive, props, isLoading } = useActionBar()
    const cancel = () => {
      props.value.cancel()
    }
    const approve = async () => {
      props.value.approve()
    }
    const text = computed(() => props.value.text)
    const canApprove = computed(() => props.value.canApprove)
    const approveText = computed(() => props.value.approveText || 'Proceed')
    return { cancel, approve, approveText, isActive, text, isLoading, canApprove }
  }
})
</script>
<style lang="scss">
.app-action-bar {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 90%;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  min-height: 50px;
  border: 1px solid var(--border);
  border-radius: 10px;
  background-color: var(--gray);
  box-sizing: border-box;
  padding: 5px 15px;
  &-actions {
    display: flex;
    align-items: center;
  }
  &-text {
    flex: 1;
  }
  &-action {
    margin-left: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::last-child {
      margin-left: 0;
    }
    &:hover {
      filter: brightness(0.8);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    &-success {
      background-color: var(--green);

      &.loading {
        pointer-events: none;
        opacity: 0.5;
      }
      img {
        width: 16px;
        margin-left: 5px;
        display: block;
      }
    }
    &-cancel {
      background-color: var(--red);
    }
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    &-text {
      width: 100%;
    }
    &-actions {
      margin-top: 8px;
      width: 100%;
    }
    &-action {
      flex: 1;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.t-action-bar-enter-from,
.t-action-bar-leave-to {
  transform: translate(-50%, 100%);
  opacity: 0;
  visibility: hidden;
}
.t-action-bar-enter-active,
.t-action-bar-leave-active {
  transition: 0.3s;
}
</style>