<template>
  <div class="header-wrapper">
    <header class="BAP-header">
      <div class="BAP-header__logo">
        <img
          :src="
            isWhite
              ? 'https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/4941583f-6c17-4c05-5a58-2db9769faf00/original'
              : '/favicon.png'
          "
          alt="Bulls & Apes Project"
        >
      </div>
      <nav ref="navEl" class="BAP-header__nav" :data-active="isDrawerOpen">
        <ul class="BAP-header__nav--menu">
          <NavItem
            v-for="content in contents"
            :key="content.label"
            :items="content.items"
            :link="content.link"
            :label="content.label"
            :announcement="content.announcement"
            :main="!content.items"
            :opened="openedMenu === content.label.toLowerCase().trim()"
            @click="setMenuOpen"
          />
        </ul>
      </nav>
      <!--<div class="BAP-header__socials">
        <a
          v-for="item in social"
          :key="item.label"
          :href="item.link"
          class="BAP-header__nav__button"
          target="_blank"
          rel="noopener noreferrer"
          :title="item.name"
        >
          <img
            :src="item.icon"
            :alt="item.name"
          >
        </a>
      </div>-->
    <AHeaderWallet />

      <button
        ref="drawerButton"
        :data-active="isDrawerOpen"
        class="BAP-header__drawer-button"
        title="drawer"
        type="button"
        @click="toggleDrawer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </header>
  </div>
</template>

<script>
import { contents, social } from "../config/data";
import ILink from "../assets/icons/external-link.svg?inline";
import IAlarm from "../assets/icons/alarm.svg?inline";
import NavItem from "./NavItem.vue";
import { useWeb3Store } from "@/store/web3";
import { useMethBankStore } from "@/store/meth-bank";
import { connectWallet } from "@/web3";
import AHeaderWallet from "./AHeader/AHeaderWallet.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";

let openedMenu = "";
let isDrawerOpen = false;

function toggleDrawer() {
  this.isDrawerOpen = !this.isDrawerOpen;
}

function setMenuOpen(e) {
  const menuName = e.target.innerText?.toLowerCase().trim();
  this.openedMenu = this.openedMenu === menuName ? "" : menuName;
}

function closeDrawer() {
  this.isDrawerOpen = false;
}

export default {
  components: {
    IAlarm,
    ILink,
    NavItem,
    IWallet,
    AHeaderWallet,
  },
  data() {
    return {
      contents,
      social,
      openedMenu,
      isDrawerOpen,
    };
  },
  computed: {
    hideHeader() {
      return this.$route.meta && this.$route.meta.showHeader === false;
    },
    walletAddress() {
      return useWeb3Store().walletAddress;
    },
    isConnected() {
      return useWeb3Store().isConnected;
    },
    balance() {
      const eth = useWeb3Store()?.balance?.toFixed(6);
      const methBank = useMethBankStore();
      const meth = useWeb3Store()?.meth;

      return { eth, meth };
    },
    isWhite() {
      return !!this.$route.meta?.white;
    },
  },
  created() {
    this.clickEvent = null;
    this.resizeEvent = null;
  },
  mounted() {
    var self = this;
    this.resizeEvent = addEventListener(
      "resize",
      function () {
        if (innerWidth > 1150) {
          self.isDrawerOpen = false;
        }
      },
      false
    );
    this.clickEvent = addEventListener(
      "click",
      function (e) {
        if (
          self.isDrawerOpen &&
          !self.$refs.navEl?.contains(e.target) &&
          !self.$refs.drawerButton?.contains(e.target)
        ) {
          self.isDrawerOpen = false;
        }
      },
      false
    );
  },
  unmounted() {
    window.removeEventListener("resize", self.resizeEvent);
    window.removeEventListener("click", self.clickEvent);
  },

  methods: {
    setMenuOpen,
    toggleDrawer,
    closeDrawer,
    connect: () => {
      connectWallet();
    },
  },

  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
