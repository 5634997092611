import { ref } from "vue-demi";

const isActive = ref(false);
const isLoading = ref(false);
const props = ref({ approve: () => {}, cancel: () => {} });
// {approve, cancel, text}
export const useActionBar = () => {
  const show = (opts = {}) => {
    isActive.value = true;
    props.value = {
      approve: opts.approve || (() => {}),
      cancel: opts.cancel || (() => {}),
      text: opts.text || "",
      canApprove: opts.canApprove,
      approveText: opts.approveText
    };
  };
  const hide = () => {
    isActive.value = false;
    isLoading.value = false;
  };
  return {
    isLoading,
    props,
    show,
    hide,
    isActive,
  };
};
