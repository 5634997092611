<template>
  <div
    class="toast-item"
    :class="[item.type]"
  >
    <div class="toast-item__icon">
      <img
        v-if="isTransaction"
        src="@/assets/img/spinner-clear.svg"
        class="loader"
        width="30"
      >
      <component
        :is="icon"
        v-else
        width="21"
      />
    </div>
    <div class="toast-item__content">
      <div
        v-if="item.title"
        class="toast-item__title"
        v-html="item.title"
      />
      <div
        class="toast-item__description"
        v-html="item.text"
      />
      <div
        v-if="transactionHash"
        class="toast-item__transaction"
      >
        View on
        <a
          target="_blank"
          :href="transactionURL"
        >
          Etherscan
          <ILink width="14" />
        </a>
      </div>
    </div>
    <a
      href="javascript:void(0)"
      class="toast-item__close"
      @click.prevent="$emit('remove')"
    >
      <svgClose width="25" />
    </a>
  </div>
</template>
<script>
import { computed } from 'vue'
import svgSuccess from '@/assets/icons/checkmark.svg?inline'
// import svgError from '@/assets/icons/toast_error.svg?inline'
// import svgWarning from '@/assets/icons/toast_warning.svg?inline'
// import svgInfo from '@/assets/icons/toast_info.svg?inline'
import svgClose from '@/assets/icons/close.svg?inline'
import { Config } from '@/config'
import ILink from "@/assets/icons/external-link.svg?inline"
export default {
  components: {
    svgClose,
    svgSuccess,
    ILink,
    // svgError,
    // svgWarning,
    // svgInfo,
  },
  props: {
    item: Object
  },
  setup(props) {
    const icon = computed(() => {
      const type = props.item.type
      const icons = {
        success: svgSuccess
      }
      return icons[type]
      // if (type === 'success') {
      //   return svgSuccess
      // } else if (type === 'error') {
      //   return svgError
      // } else if (type == 'warning') {
      //   return svgWarning
      // } else if (type === 'info') {
      //   return svgInfo
      // }
      // return type
    })
    const toastTitle = computed(() => {
      if (props.item.title) return props.item.title
      return `${props.item.type[0].toUpperCase()}${props.item.type.slice(1)}`
    })
    const isTransaction = computed(() => {
      return props.item.type === 'transaction'
    })
    const transactionHash = computed(() => {
      return props.item.tx?.hash
    })
    const transactionURL = computed(() => {
      return `${Config.network.scan}/tx/${transactionHash.value}`
    })
    return { icon, toastTitle, isTransaction, transactionURL, transactionHash }
  }
}
</script>

<style lang="scss">
.toast-item {
  background-color: var(--gray);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 500px;
  min-width: 250px;
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  &__content {
    min-width: 0;
  }
  &__title {
    font-weight: bold;
    margin-bottom: 3px;
  }
  &__description {
    font-size: 14px;
    a {
      color: var(--primary);
    }
  }
  &__transaction {
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    padding-top: 5px;
    border-top: 1px solid var(--border);
    a {
      text-decoration: none;
      color: var(--primary);
      &:hover {
        text-decoration: underline;
      }
      svg {
        vertical-align: bottom;
      }
    }
  }

  &__close {
    flex-shrink: 0;
    color: #fff;
    padding-left: 15px;
    margin-left: auto;
    opacity: 0.5;
    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  &__icon {
    margin-right: 5px;
  }
  &.success {
    border: 1px solid var(--green);
    background-color: #0c4c22;
  }
  &.error {
    border: 1px solid var(--red);
    background: #8a1616;
  }
  &.transaction {
    border: 1px solid var(--border);
  }
  &.info {
    border: 1px solid #0059d4;
    background: #0a4392;
  }
}
</style>
