import { Config } from "../config";
import { useWeb3Store } from "../store/web3";
import Web3 from "web3";
import { providerOptions } from "./provider-options";
import Web3Modal from "web3modal";
import { ref } from "vue-demi";
import router from "@/router";

let provider;
let web3;
const inited = ref(false);

const web3Modal = new Web3Modal({
  network: Config.network.name,
  cacheProvider: true,
  providerOptions: providerOptions,
});

const onAccountChange = async ([walletAddress]) => {
  const web3Store = useWeb3Store();
  console.log("change acc", walletAddress);
  if (!walletAddress) {
    console.log("reset");
    web3Store.$reset();
    return;
  }
  web3Store.setWalletAddress(walletAddress);
  await web3Store.update();
};
const onChaingChange = async (info) => {
  const web3Store = useWeb3Store();
  await web3Store.updateNetwork();
  if (web3Store.isWrongNetwork || !web3Store.isConnected) return;
  await web3Store.update();
};
const onDisconnect = (error) => {
  useWeb3Store().$reset();
  console.log("disconnect", error);
};
const onConnect = (info) => {
  console.log("connect", info);
};
async function connectWallet() {
  try {
    provider = await web3Modal.connect();
    if (!provider) return;
  } catch (err) {
    console.error("Error connecting wallet", err);
    return;
  }

  provider.on("accountsChanged", onAccountChange);

  provider.on("chainChanged", onChaingChange);

  provider.on("connect", onConnect);

  provider.on("disconnect", onDisconnect);

  web3 = new Web3(provider);
  const web3Store = useWeb3Store();
  const accs = await web3.eth.getAccounts();

  let overwriteWallet;
  try {
    overwriteWallet = web3.utils.toChecksumAddress(
      router.currentRoute.value.query.view_wallet_address
    );
  } catch (err) {
    console.error(err);
  }
  if (overwriteWallet && Config.mode !== 'production') {
    web3Store.setWalletAddress(overwriteWallet);
  } else {
    web3Store.setWalletAddress(accs[0]);
  }
  checkWalletType();
  console.log(web3Store.walletType);
  await web3Store.update();

  return true;
}

const checkWalletType = () => {
  const web3Store = useWeb3Store();
  if (web3?.currentProvider?.isWalletConnect) {
    web3Store.walletType = "WalletConnect";
  } else if (typeof ethereum !== "undefined") {
    if (ethereum.isTokenPocket) {
      web3Store.walletType = "TokenPocket";
    } else if (ethereum.selectedProvider?.isCoinbaseWallet) {
      web3Store.walletType = "CoinbaseWallet";
    } else if (ethereum.isMetaMask) {
      web3Store.walletType = "MetaMask";
    }
  }
};

const init = async () => {
  try {
    const ethereum = window.ethereum;
    const web3Store = useWeb3Store();
    if (typeof ethereum !== "undefined") {
      checkWalletType();
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length && web3Modal.cachedProvider) {
          await connectWallet();
        }
      } catch (err) {
        console.error("Error trying to request wallet accounts", err);
      }
    }
  } catch (err) {
    console.log("err while init wallet", err);
  }
  inited.value = true;
};
const disconnectWallet = () => {
  web3Modal.clearCachedProvider();
  web3.currentProvider.disconnect && web3.currentProvider.disconnect();
  useWeb3Store().$reset();

  router.push({ name: "Home" })
  return
};
export { connectWallet, web3, disconnectWallet, web3Modal, init, inited };
