<template>
    <div class="header-wallet" :class="{ open: showTools }">
        <div v-if="!isConnected">
            <button @click="connectWallet" class="header-wallet-connect">
                <IWallet width="20" />
                <span>Connect Wallet</span>
            </button>
        </div>
        <div class="header-wallet-info" @click.prevent="toggleTools" v-else>
            <span class="header-wallet-info-content">
                <IWallet class="header-wallet-info-wallet" width="20" />
                {{ walletAddress }}
            </span>
            <IArrowDown class="header-wallet-info-arrow" width="16" />
        </div>
        <AHeaderTools v-if="showTools && isConnected" v-click-outside="hideTools" />
    </div>
</template>
<script setup>
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";
import IArrowDown from "@/assets/icons/arrow-down.svg?inline";
import { shorten } from "@/helpers/utils";
import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
import { computed, ref } from "vue-demi";
import AHeaderTools from "./AHeaderTools.vue";

const web3Store = useWeb3Store()
const methBankStore = useMethBankStore()

const showTools = ref(false)

const toggleTools = () => {
    showTools.value = !showTools.value
}
const hideTools = () => {
    showTools.value = false
}

const walletAddress = computed(() => {
    return shorten(web3Store.walletAddress, 4, 4);
})
const isConnected = computed(() => {
    if(!web3Store.isConnected && showTools.value) showTools.value = false

    return web3Store.isConnected;
})
const balance = computed(() => {
    const eth = web3Store?.balance?.toFixed(6);
    const meth = web3Store?.meth;

    return { eth, meth };
})
</script>
<style lang="scss">
.header-wallet {
    position: relative;
    width: 200px;
    &-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;
        border-radius: 8px;
        color: inherit;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        border: 1px solid var(--border);
        position: relative;
        &-arrow {
            transition: 0.2s;
            color: inherit;
        }
        &-wallet {
            margin-right: 5px;
        }
        &-content {
            display: flex;
            align-items: center;
        }

        &:hover {
            background-color: hsl(0 0% 100% / 0.1);
        }
    }

    &-connect {
        border: 1px solid hsl(0 0% 100% / 0.2);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;
        color: #f3f3f3;
        background-color: transparent;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: all ease-in-out 0.2s;

        &:hover {
            background-color: hsl(0 0% 100% / 0.1);
        }

        & svg {
            color: #3dd1d1;
        }
    }
    &.open & {
        &-info-arrow {
            transform: rotate(180deg);
        }
    }
}
</style>