<script>
import AToastItem from './AToastItem.vue'
import { toastList, useToast } from '@/composables/useToast/useToast'
export default {
  components: {
    AToastItem
  },
  setup() {
    const removeItem = (id) => {
      const { hide } = useToast()
      hide(id)
    }
    return { removeItem, toastList }
  }
}
</script>

<template>
  <div class="toast-container">
    <TransitionGroup
      name="t-toast"
      tag="div"
      class="toast-container-items"
    >
      <AToastItem
        v-for="item in toastList"
        :key="item.id"
        :item="item.options"
        @remove="removeItem(item.id)"
      />
    </TransitionGroup>
  </div>
</template>

<style lang="scss">
.toast-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 180;
  &-items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.t-toast-enter-from,
.t-toast-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.t-toast-enter-active,
.t-toast-leave-active {
  transition: 0.3s;
}
</style>
