import { useHRApi } from "@/api/useHRApi";
import { Config } from "@/config";
import { TokenType } from "@/config/token-types";
import { loadTeenTraits } from "@/helpers/utils";
import { useWeb3Store } from "@/store/web3";
import { useInfoReaderContract } from "@/web3/contracts";

import { fetchTokensMeth } from "./fetchTokensMeth";

export const fetchTeens = async (teens, teensCount) => {
  const levels = await fetchTeensLevels(teens);

  const teensIds = teens.map((el) => el.id);
  // const teensData = await useInfoReaderContract()
  //   .methods.getTeensInfoBatch(teensIds)
  //   .call();
  const newTeens = await Promise.all(
    teens.map(async (teen) => {
      const token = teen;
      token.id = Number(token.id);
      token.isTeen = true;
      // let claimableMeth = 0;
      // const teenData = teensData.find((teen) => teen.tokenId == token.id);
      // claimableMeth = teenData["claimableMeth"];

      const extraInfo = token.metadata
        ? token.metadata
        : await loadTeenTraits(token.uri);

      
      const levelsInfo = levels[token.id];
      const level = levelsInfo.level;
      const harvestInfo = {
        level,
        harvestPerDay: levelsInfo.meth_amount,
        isHarvestedToday:
          levelsInfo.harvested_today ||
          levelsInfo.harvested_today_another_wallet,
        daysLeft: levelsInfo.days_until_next_level,
      };
      // Merge attributes to build the final token meta
      return {
        ...token,
        ...extraInfo,
        type: TokenType.TEEN,
        harvestInfo,
      };
    })
  );

  const claimableMethItems = await fetchTokensMeth(
    newTeens.filter((teen) => teen.isResurrected)
  );
  const claimableMethInfo = claimableMethItems.reduce((obj, item) => {
    obj[item.id] = item.claimableMeth;
    return obj;
  }, {});
  newTeens.forEach((teen) => {
    if (teen.isResurrected) {
      teen.claimableMeth = claimableMethInfo[teen.id];
    }
  });
  // Padd collection with placeholders
  const teenDiff = Number(teensCount) - teens.length;
  if (teenDiff > 0) {
    // Fill the missing tokens with a placeholder
    for (let i = 0; i < teenDiff; i++) {
      const placeHolderToken = {
        guild: null,
        id: 0,
        image: require("@/assets/img/Bulls/bull-placeholder.gif"),
        symbol: "BAPTEENB",
        type: TokenType.TEEN,
        harvestInfo: {
          level: 1,
          harvestPerDay: 1,
          isHarvestedToday: false,
          daysLeft: 7,
        },
      };
      newTeens.push(placeHolderToken);
    }
  }
  return newTeens;
};

const fetchTeensLevels = async (teens) => {
  if (!teens.length) return {};

  const wallet = useWeb3Store().collectionWallet;
  let teensLevels = {};

  // const pages = Math.floor(teens.length / 500);
  for (let start = 0; start < teens.length; start += 500) {
    // const start = index * 500;
    const end = start + 500 < teens.length ? start + 500 : undefined;

    const data = await useHRApi({ throw: true }).exec({
      url: "/v1/bap/teens-levels",
      params: {
        wallet_address: wallet,
        // TEMP
        network:
          Config.network.name === "sepolia" ? "goerli" : Config.network.name,
        teen_ids: teens.map((teen) => teen.id).slice(start, end),
        version: Config.mode === "staging" ? 2 : undefined,
      },
    });
    teensLevels = { ...teensLevels, ...data.levels };
  }

  return teensLevels;
};
