import { useBapApi } from "@/api/useBapApi";
import { Config } from "@/config";
import { TokenType } from "@/config/token-types";
import { useWeb3Store } from "@/store/web3";
import { fetchPartnerLevels } from "./fetchPartnerLevels";

export const fetchBotbTokens = async () => {
  const wallet = useWeb3Store().collectionWallet;
  const { success, result } = await useBapApi({ throw: true }).exec({
    url: `/mint/botb`,
    params: {
      wallet: wallet,
    },
  });

  const botbOG = result.ogTokens;

  const botbEVO = result.evoTokens;
  const botbBears = result.bearTokens;

  const specialsTokens = result.specialsTokens;

  const botb = {
    botbOG,
    botbEVO,
    botbBears,
  };

  const [botbOgLevels, bearsLevels, evoLevels] = await Promise.all([
    fetchPartnerLevels(
      Config.BOTBAddress.OG,
      botbOG.map((item) => item.id)
    ),
    fetchPartnerLevels(
      Config.BOTBAddress.Bears,
      botbBears.map((item) => item.id)
    ),
    fetchPartnerLevels(
      Config.BOTBAddress.EVO,
      botbEVO.map((item) => item.id)
    ),
  ]);
  const setIds = [];
  botbOG.forEach((token) => {
    const id = token.id;

    const hasSuchIdInOtherCollections = [botbBears, botbEVO]
      .map((tokens) => {
        return tokens.findIndex((item) => item.id == id) > -1;
      })
      .every(Boolean);

    if (hasSuchIdInOtherCollections) {
      setIds.push(id);
    }
  });
  const levels = {
    botbOG: botbOgLevels,
    botbBears: bearsLevels,
    botbEVO: evoLevels,
  };
  const types = {
    botbOG: TokenType.BOTBOG,
    botbEVO: TokenType.BOTBEVO,
    botbBears: TokenType.BOTBBEARS,
  };
  Object.keys(botb).forEach((key) => {
    botb[key] = botb[key].map((token) => {
      const levelInfo = levels[key][token.id] || {};
      return {
        ...token,
        type: types[key],
        isInSet: setIds.includes(token.id),
        harvestInfo: {
          level: levelInfo.level,
          harvestPerDay: levelInfo.meth_amount,
          isHarvestedToday:
            levelInfo.harvested_today ||
            levelInfo.harvested_today_another_wallet,
          daysLeft: levelInfo.days_until_next_level,
        },
      };
    });
  });

  // botb special
  const botbSpecial = specialsTokens.map((token) => ({
    ...token,
    type: TokenType.BOTB_SPECIAL,
  }));
  return { ...botb, botbSpecial };
};
