import { markRaw, ref } from "vue";
const defaultDuration = 8000;

export const toastList = ref([]);

export class ToastItem {
  constructor(opts, id) {
    this.options = opts;
    this.title = opts.title;
    this.text = opts.text;
    this.type = opts.type;
    this.tx = opts.tx;
    this.id = id;
    this.duration = this.options.duration || defaultDuration;
    this.init();
  }

  remove() {
    toastList.value = toastList.value.filter((item) => item.id !== this.id);

    this.stopTimer();
  }
  stopTimer() {
    if (!this.timer) return;
    clearTimeout(this.timer);
    this.timer = null;
  }
  startTimer() {
    this.timer = setTimeout(() => {
      this.remove();
    }, this.duration);
  }
  init() {
    if (this.duration > 0) {
      this.startTimer();
    }
  }
}
const toastId = ref(1);
export const useToast = () => {
  const show = (opts) => {
    const item = new ToastItem(opts, toastId.value++);
    toastList.value.push(item);
    return item;
  };
  const hide = (id) => {
    const item = toastList.value.find((item) => item.id === id);
    if (!item) return;
    item.remove();
  };
  const success = (opts) => show({ ...opts, type: "success" });

  const error = (opts) => show({ ...opts, type: "error" });

  return { show, hide, success, error };
};
