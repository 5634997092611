<script>
import { useToggle } from '@vueuse/core'
import { computed, markRaw, ref, shallowRef } from 'vue'
import { useModal } from '@/composables/useModal'
export default {
  setup() {
    const [isOpen, toggle] = useToggle()
    const { bus, close } = useModal()
    const modalComponent = shallowRef()

    const modalEl = ref(null)
    const defaultModalOptions = {
      closeOnOverlay: true,
    }
    const openedOptions = ref({})
    const componentOptions = ref({})
    const modalOptions = computed(() => Object.assign({}, defaultModalOptions, componentOptions.value, openedOptions.value))
    bus.on('open', async (opts) => {
      try {
        openedOptions.value = opts
        const { default: modal } = await import(`../Modals/${opts.name}.vue`)
        modalComponent.value = modal
        toggle(true)
      } catch (err) {
        console.log('Error while opening modal ', err)
      }
    })
    const modalProps = computed(() => modalOptions.value?.props)
    bus.on('close', (name) => {
      if (!name) {
        toggle(false)
      } else if (openedOptions.value.name === name) {
        toggle(false)
      }
    })
    const clickOverlay = () => {
      if (modalOptions.value?.closeOnOverlay) {
        close()
      }
    }
    const onInit = (options) => {
      componentOptions.value = options
    }
    return {
      clickOverlay, onInit, isOpen, close, modalComponent, modalOptions, modalProps
    }
  }
}
</script>

<template>
  <transition name="t-modal">
    <div
      v-if="isOpen"
      class="app-modal-wrapper"
    >
      <div
        class="app-modal-overlay"
        @click="clickOverlay"
      />
      <component
        :is="modalComponent"
        class="app-modal"
        v-bind="modalProps"
        @close="close"
        @init="onInit"
      />
    </div>
  </transition>
</template>

<style lang="scss">
.app-modal {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  max-width: 100%;
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 150;
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(14px);
  }
}
.t-modal-enter-from,
.t-modal-leave-to {
  .app-modal-overlay {
    backdrop-filter: blur(14px);
    opacity: 0;
  }
  .app-modal {
    opacity: 0;
  }
}
.t-modal-enter-active,
.t-modal-leave-active {
  transition: 0.3s;

  .app-modal-overlay,
  .app-modal {
    transition: 0.3s;
  }
}
</style>
