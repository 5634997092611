import VueGtag from "vue-gtag";

export const useGTMInit = (app, router) => {
  if (process.env.VUE_APP_ENV === "production") {
    app.use(
      VueGtag,
      {
        config: { id: "G-2RRKFWL2QZ" },
      },
      router
    );
  }
};
