import { useHRApi } from "@/api/useHRApi";
import { Config } from "@/config";
import { TokensContracts } from "@/config/tokens-contracts";
import { getTokenContractAddress } from "@/helpers/get-token-contract-address";
import { getTokenTypeByContract } from "@/helpers/get-token-type-by-contract";

export const fetchTokensMeth = async (tokens) => {
  if (!tokens.length) return [];
  const assets = {};
  tokens.forEach((token) => {
    const contractAddress = getTokenContractAddress(token);
    if (!assets[contractAddress]) assets[contractAddress] = [];
    assets[contractAddress].push(token.id.toString());
  });
  const res = await useHRApi({ throw: true }).exec({
    url: "/v1/bap/meth-balance",
    params: {
      network: Config.network.name,
      assets: JSON.stringify(assets),
    },
  });

  const result = [];
  Object.keys(res.balances).forEach((contract) => {
    const collection = res.balances[contract];
    const tokenType = getTokenTypeByContract(contract);
    Object.keys(collection).forEach((itemId) => {
      result.push({
        type: tokenType,
        id: itemId,
        claimableMeth: collection[itemId].balance,
      });
    });
  });

  return result;
};
