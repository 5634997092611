<template>
    <div class="header-tools">
        <div class="header-tools-balance header-tools-section">
            <div class="header-tools-balance-title header-tools-section-title">Balance (on-chain)</div>
            <div class="header-tools-balance-row">
                <div class="header-tools-balance-item header-tools-balance-eth">{{ ethBalance }}</div>
                <div
                    class="header-tools-balance-item header-tools-balance-meth"
                >{{ methBalance.onChain }} METH</div>
            </div>
        </div>   
        <div class="header-tools-section header-tools-safeclaim" v-if="canBeController">
            <div class="header-tools-section-title header-tools-safeclaim-title">
                Safe Claim
                <div
                    class="header-tools-safeclaim-status"
                    :class="{ active: isController }"
                >{{ isController ? 'Active' : 'Disabled' }}</div>
            </div>

            <div class="header-tools-safeclaim-wallet" v-if="isController">
                Currently viewing
                <b>{{ controllerSource }}</b>
            </div>
            <a
                href="javascript:void(0)"
                class="header-tools-action header-tools-action-safeclaim"
                @click.prevent="switchSafeClaim"
            >
                <ILock width="16" />
                <span v-if="isController">De-Activate Safe Claim</span>
                <span v-else>Activate Safe Claim</span>
            </a>
        </div>
        <div class="header-tools-section header-tools-safeclaim">        
            <a
                href="javascript:void(0)"
                class="header-tools-action header-tools-action-safeclaim"
                @click.prevent="disconnect"
            >          
            <IDisconnect width="12" /> <span>Disconnect Wallet</span>
            </a>
        </div>
        <div class="header-tools-section header-tools-meth-bank">
            <div class="header-tools-section-title">Meth Bank (off-chain)</div>
            <div class="header-tools-meth-bank-balance">{{ methBalance.offChain }} METH</div>

            <div class="header-tools-actions-row">
                <a href="javascript:void(0)" class="header-tools-action" @click.prevent="sendMeth">
                    <ISend width="14" />Send METH
                </a>
                <a
                    href="javascript:void(0)"
                    class="header-tools-action"
                    @click.prevent="depositMeth"
                    v-tooltip="{ content: 'Transfer On-Chain METH to METH Bank', distance: 10 }"
                >
                    <IPlus width="14" />Deposit METH
                </a>
            </div>
            <a
                href="javascript:void(0)"
                class="header-tools-action header-tools-action-buy"
                @click.prevent="buyMeth"
            >Buy METH</a>
        </div>
    </div>
</template>
<script setup>
import ISend from "@/assets/icons/send.svg?inline"
import IPlus from "@/assets/icons/plus.svg?inline"
import ILock from "@/assets/icons/lock.svg?inline"

import { formatNumberSpaces } from "@/helpers/format-number-spaces"
import { useWeb3Store } from "@/store/web3"
import { disconnectWallet } from "@/web3";
import { computed } from "vue"
import { useMethBankStore } from "@/store/meth-bank"
import { useModal } from "@/composables/useModal"
import { ModalName } from "../Modal/modal-name"
import { shorten } from "@/helpers/utils"
import IDisconnect from "@/assets/icons/disconnect.svg?inline"
/* buy meth
transfer meth
deposit meth
switch safeclaim
disconnect
*/
const emit = defineEmits(['close'])

const web3Store = useWeb3Store()
const ethBalance = computed(() => {
    const { balance } = useWeb3Store()
    return `${balance.toFixed(6)} ETH`
})
const methBalance = computed(() => {
    return {
        onChain: formatNumberSpaces(web3Store.meth),
        offChain: formatNumberSpaces(useMethBankStore().balance)
    }
})
const sendMeth = () => {
    emit('close')
    useModal().open({
        name: ModalName.SendMethModal,
        props: {
            onChain: false
        }
    })
}

const disconnect = () => {
    disconnectWallet()  
}

const buyMeth = () => {
    emit('close')
    useModal().open({ name: ModalName.BuyMethModal })
}
const depositMeth = () => {
    emit('close')
    useModal().open({
        name: ModalName.DepositMethBankModal,
    })
}
const switchSafeClaim = () => {
    web3Store.switchSafeClaim()
}

const canBeController = computed(() => web3Store.canBeController)
const isController = computed(() => web3Store.safeClaim.isController)
const controllerSource = computed(() => shorten(web3Store.safeClaim.sourceWallet, 4, 4))
</script>
<style lang="scss">
.header-tools {
    position: absolute;
    bottom: -5px;
    border-radius: 5px;
    transform: translateY(100%);
    right: 0;
    min-width: 300px;
    border: 1px solid var(--border);
    box-sizing: border-box;
    padding: 5px;
    z-index: 300;
    background-color: var(--bg);
    color: #fff;
    &-row {
        display: flex;
        align-items: center;
    }
    &-section {
        padding: 5px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--border);
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        &-title {
            text-transform: uppercase;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 3px;
            font-weight: bold;
        }
    }
    &-balance {
        font-weight: bold;
        border-top: none;

        &-row {
            display: flex;
            align-content: center;
            justify-content: center;
        }
        &-item {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
        &-eth {
            color: rgb(28, 106, 219);
        }
    }
    &-actions {
        &-row {
            display: flex;
            align-items: center;
        }
    }
    &-meth-bank {
        &-balance {
            text-align: center;
            margin-bottom: 5px;
            font-weight: bold;
        }
    }
    &-safeclaim {
        text-align: center;
        &-title {
            margin-bottom: 8px;
        }
        &-status {
            display: inline-block;
            text-transform: uppercase;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.8);
            border: 1px solid currentColor;
            padding: 1px 8px;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 14px;
            margin-left: 5px;
            &.active {
                color: var(--green);
            }
        }
        &-wallet {
            margin: 5px 0;
        }
    }
    &-actions-row & {
        &-action {
            &:nth-child(2n + 1) {
                margin-right: 5px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-action {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border);
        border-radius: 5px;
        padding: 8px 5px;
        box-sizing: border-box;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        background-color: var(--gray-light);
        transition: 0.2s;
        box-sizing: border-box;
        font-weight: bold;
        svg {
            margin-right: 5px;
        }
        &:hover {
            background-color: transparent;
        }
        &-buy {
            width: 100%;
            margin-top: 5px;
            background-color: var(--primary);
        }
        &-safeclaim {
            width: 100%;
        }
    }
    @media screen and (max-width: 1150px) {
        left: 50%;
        transform: translate(-50%, 100%);
    }
}
</style>