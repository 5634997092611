import * as Sentry from "@sentry/vue";
import { Replay } from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
export const useSentryInit = (app, router) => {
  if (
    process.env.VUE_APP_ENV == "production" ||
    (process.env.VUE_APP_ENV === "staging" &&
      process.env.VUE_APP_NETWORK === "mainnet")
  ) {
    Sentry.init({
      app,
      dsn: "https://e1366b4edbb84b3aa06869243e0a74e1@o1373943.ingest.sentry.io/4504264452276224",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [
            "localhost",
            "https://mint.bullsandapesproject.com/",
            /^\//,
          ],
        }),
        new Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      environment: process.env.VUE_APP_ENV,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [ /Loading chunk .*? failed/]
    });
  }
};
