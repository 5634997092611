export default [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/FrontPage.vue"),
    props: true,
    meta: {
      white: true,
    },
  },
  {
    path: "/pre-mint",
    name: "pre-mint",
    component: () => import("../views/PreMint.vue"),
  },
  {
    path: "/ape-mint",
    name: "ape-mint",
    component: () => import("../views/ApeMint.vue"),
  },
  {
    path: "/list-ape-mint",
    name: "list-ape-mint",
    component: () => import("../views/ListApeMint.vue"),
  },
  {
    path: "/events/mega-meetup",
    name: "mega-meetup",
    component: () => import("../views/MegaMeetup.vue"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/events/god-bull-irl",
    name: "god-bull-irl",
    component: () => import("../views/GodBullIRL.vue"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/harvest-leaderboard",
    name: "harvest-leaderboard",
    component: () => import("../views/HarvestLeaderboard.vue"),
  },
  {
    path: "/safeclaim",
    name: "SafeClaim",
    component: () => import("../views/SafeClaim.vue"),
    props: true,
  },
  {
    path: "/botb",
    name: "botb home",
    component: () => import("../views/ConnectWalletBOTB.vue"),
    meta: {
      layout: "empty",
    },
  },
  //{ path: '/mint', name: 'minting', component: MintingView, props: true },
  {
    path: "/collection",
    name: "Collection",
    component: () => import("../views/Collection.vue"),
    props: true,
  },
  {
    path: "/god-ape-quest",
    name: "GodApeQuest",
    component: () => import("../views/GodApeQuest.vue"),
    props: true,
  },
  {
    path: "/theshop",
    name: "TraitShop",
    component: () => import("../views/TraitShop.vue"),
    props: true,
  },
  {
    path: "/achievements",
    name: "Achievements",
    component: () => import("../views/Achievements.vue"),
    props: true,
  },
  {
    path: "/formulas",
    name: "Formulas",
    component: () => import("../views/Formulas.vue"),
    props: true,
  },
  // {
  //   path: "/tickets",
  //   name: "TicketSale",
  //   component: () => import("../views/TicketSale.vue"),
  //   props: true,
  // },
  {
    path: "/botb/claim",
    name: "botb claim",
    component: () => import("../views/ClaimBOTB.vue"),
    // component: () => import("../views/CollectionBOTB.vue"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/botb/mint",
    name: "Botb Mint",
    component: () => import("../views/MintBOTB.vue"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/traitranks",
    name: "Traitranks",
    component: () => import("../views/TraitRanks.vue"),
  },
  {
    path: "/traitrank",
    name: "traitrank-redirect",
    redirect: { name: "Traitranks" },
  },
  {
    path: "/bapherdaward",
    name: "bapherdaward",
    component: () => import("../views/Reward.vue"),
  },
  {
    path: "/claimherdreward",
    name: "claimherdreward",
    component: () => import("../views/ClaimReward.vue"),
  },
  {
    path: "/referralprogram",
    name: "referralprogram",
    component: () => import("../views/ReferralProgram.vue"),
  },
  {
    path: "/discord",
    name: "discord",
    component: () => import("../views/Discord.vue"),
  },
  {
    path: "/rewardleaderboard",
    name: "rewardleaderboard",
    component: () => import("../views/RewardLeaderBoard.vue"),
  },
  {
    path: "/500",
    name: "error-500",
    component: () => import("../views/Error500.vue"),
    props: true,
  },
  {
    path: "/bapbeat",
    name: "bapbeat",
    component: () => import("../views/BapBeat.vue"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/ape-configurator",
    redirect: { name: "Home" },
  },
  

  {
    path: "/ape-configurator/:id",
    name: "ApeConfigurator",
    component: () => import("../views/ApeConstructor.vue"),
  },
  {
    path: "/nft-constructor",
    name: "NftConstructor",
    component: () => import("../views/NftConstructor.vue"),
  },
  {
    path: "/lootbox",
    name: "LootBox",
    component: () => import("../views/LootBox.vue"),
  },
  {
    path: "/crossmint-success",
    name: "CrossMintSuccess",
    component: () => import("../views/CrossMintSuccess.vue"),
  },
  {
    path: "/community-activity",
    name: "CommunityActivity",
    component: () => import("../views/CommunityActivity.vue"),

  },

  { path: "/:catchAll(.*)", redirect: "/" },
];
