export const contents = [
  {
    label: "Home",
    link: "https://www.bullsandapesproject.com/",
  },
  {
    label: "Collection",
    items: [
      {
        label: "My Collection",
        link: "/",
      },
      {
        label: "TraitRanks",
        link: "/traitranks",
      },
      {
        label: "OG Bulls Collection",
        link: "https://opensea.io/collection/bullsandapes-genesis",
      },
      {
        label: "Teen Bulls Collection",
        link: "https://opensea.io/collection/bullsandapes-teenbulls",
      },
      {
        label: "Utilities Collection",
        link: "https://opensea.io/collection/bullsandapes-utilities",
      },
      {
        label: "Apes",
        link: "https://opensea.io/collection/bullsandapes-apes",
      },
      {
        label: "Ape Traits",
        link: "https://opensea.io/collection/bullsandapes-apetraits",
      },
    ],
  },
  {
    label: "Holder Benefits",
    items: [
      {
        label: "Inner Circle",
        link: "https://www.bullsandapesproject.com/inner-circle",
      },
      {
        label: "Tokenomics",
        link: "https://www.bullsandapesproject.com/get-started",
      },
      {
        label: "Educational Courses",
        link: "https://www.bullsandapesproject.com",
      },
      {
        label: "VC Deal",
        link: "https://www.bullsandapesproject.com/VCdeal-info",
      },
      {
        label: "Configurator",
        link: "https://www.bullsandapesproject.com/config-LP",
      },
    ],
  },
  {
    label: "About B.A.P",
    items: [
      {
        label: "Lore",
        link: "https://www.bullsandapesproject.com/lore",
      },
      {
        label: "Tokenization",
        link: "https://www.bullsandapesproject.com/tokenization",
      },      
      {
        label: "Community Activity",
        link: "/community-activity",
      },
      {
        label: "Accomplishments",
        link: "https://www.bullsandapesproject.com/accomplishments",
      },
      {
        label: "Safe Claim",
        link: "/safeclaim",
      },
      {
        label: "FAQs",
        link: "https://www.bullsandapesproject.com/faqs",
      },
      {
        label: "ETH-Back Guarantee",
        link: "https://www.bullsandapesproject.com/eth-back-guarantee",
      },
      {
        label: "$METH White Paper",
        link: "https://www.bullsandapesproject.com/Meth-whitepaper",
      },
      {
        label: "Roadmap",
        link: "https://www.bullsandapesproject.com/roadmap",
      },
      {
        label: "Blog",
        link: "https://www.bullsandapesproject.com/blog",
      },
    ],
  },

  {
    label: "Formulas",
    link: "/formulas",
    announcement: true,
  },
  {
    label: "God Ape Quest",
    link: "/god-ape-quest",
    announcement: true,
  },
  {
    label: "Configurator",
    link: "/ape-configurator/any",
    announcement: true,
  },
  {
    label: "The Shop",
    link: "/theshop",
    announcement: true,
  },
  {
    label: "Loot Box",
    link: "/lootbox",
    announcement: true,
  },
  {
    label: "Achievements",
    link: "/achievements",
    announcement: true,
  },
];

export const social = [
  {
    name: "Discord",
    icon: require("../assets/img/social/discord.svg"),
    url: "https://discord.gg/bullsandapesproject",
  },
  {
    name: "Twitter",
    icon: require("../assets/img/social/icons8-twitter-26.png"),
    url: "https://twitter.com/bullsapesproj",
  },
  {
    name: "Linktree",
    icon: require("../assets/img/social/linktree.svg"),
    url: "https://linktr.ee/bullsandapesproject",
  },
];

export const messages = {
  loading: {
    type: "spinner",
    title: "Please Wait",
    body: "<p>Currently</p>Minting",
  },
  success: {
    type: "success",
    title: "Transaction Completed",
    body: null,
  },
  error: {
    type: "error",
    title: "Transaction Failed",
    body: null,
  },
  unexpectedError: {
    type: "error",
    title: "Unexpected Error",
    body: "Sorry... We hit a snag.<br>Try refreshing the page, or come back later",
  },
  installMetaMask: {
    type: "info",
    title: "Install MetaMask",
    body: '<p>You need to install MetaMask.</p>Get it <a target="_blank" href="https://metamask.io/download">from here</a>',
  },
  endOfSale: {
    type: "info",
    title: "Sales closed",
    body: "<p>No more NFT left.</p>Thanks!",
  },
  maintenance: {
    title: "The sale is temporarily closed.",
    body: `
    <p class="comment compact"style="color: rgb(225, 109, 45); font-size: 1.2rem;">There were unexpected technical issues in the UI during our Pre-sale which caused some of our early supporters to miss their minting window.
    <br><br>Those issues have now been resolved and we're temporarily pausing the Public Sale until 6PM CST to allow our early supporters to mint as promised.
    <br><br>We know you're eager to join the Inner Circle and we look forward to having you. Public minting will resume at 6PM CST.</p>`,
    ignoreHeader: true,
    undismissable: true,
  },
  comingSoon: {
    type: "info",
    title: "Coming Soon",
    body: "<p>Almost Launching</p>Wait for it :-)",
  },
  publicMintComingSoon: {
    type: "info",
    title: "Public Mint Coming Soon",
    body: "<p>Public Mint is starting soon!</p>",
    ignoreHeader: true,
  },
};
