import { useWeb3Store } from "@/store/web3";
import Contract from "web3-eth-contract";
import { web3 } from "../web3";
export const useContract = (abi, contractAddress) => (attach) => {
  if (attach) {
    const store = useWeb3Store();
    if (!store.isConnected) {
      throw new Error("Wallet Not Connected");
    }
    return new web3.eth.Contract(abi, contractAddress, {
      from: store.walletAddress,
    });
  }
  return new Contract(abi, contractAddress);
};
