import { useSentry } from "@/composables/useSentry";

export const captureError = (err, payload = {}) => {
  console.error(err);
  const p = {
    ...payload,
    contexts: { ...(payload.contexts || {}), cause: err?.cause },
  };
  useSentry().captureException(err, p);
};
