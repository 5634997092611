<template>
  <li
    :class="`BAP-header__nav--item ${
      items ? 'BAP-header__nav--extendable' : ''
    } ${announcement ? 'BAP-header__nav__announcement' : ''}`"
  >
    <a
      v-if="main"
      class="BAP-header__nav__button"
      :href="link"
    >{{ label }}</a>
    <NavSubmenu
      v-else
      :key="label"
      :items="items"
      :label="label"
      :is-opened="opened"
    />
  </li>
</template>

<script>
import NavSubmenu from "./NavSubmenu.vue";

export default {
  components: {
    NavSubmenu,
  },
  props: {
    link: {
      type: String,
    },
    label: {
      type: String,
    },
    main: {
      type: Boolean,
    },
    items: {
      type: Array,
    },
    opened: {
      type: Boolean,
    },
    announcement: {
      type: Boolean,
    },
  },
};
</script>
