import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useBapApi } from "@/api/useBapApi";
import { useHRApi } from "@/api/useHRApi";
import { Config } from "@/config";
import { TokenType } from "@/config/token-types";
import { useInfoReaderContract } from "@/web3/contracts";
import axios from "axios";

import { fetchTokensMeth } from "./fetchTokensMeth";
export const fetchGenesis = async (tokens, bullCount) => {
  const { data: godPower } = await useHRApi({
    throw: true,
  }).exec({
    url: "/v1/bap/god-power",
    params: {
      // TEMP
      network:
        Config.network.name === "sepolia" ? "goerli" : Config.network.name,
    },
  });

  let bullsIds = tokens.map((el) => el.id);
  const bullsData = await useApesStorageApi({ throw: true }).exec({
    url: "/wallet/breedings-boxes-info",
    method: "POST",
    data: {
      ids: bullsIds,
      network: Config.network.name,
    },
  });
  const godsTokens = bullsData
    .filter((item) => item.isGod)
    .map((item) => ({ id: +item.id }));
  const bullsTokens = bullsData
    .filter((item) => !item.isGod)
    .map((item) => ({ id: +item.id }));
  const [claimableMethItems] = await Promise.all([
    fetchTokensMeth(
      bullsData.map((token) => ({
        id: +token.id,
        type: token.isGod ? TokenType.GOD : TokenType.GENESIS,
      }))
    ),
  ]);
  const claimableMethInfo = claimableMethItems.reduce((obj, item) => {
    obj[item.id] = item.claimableMeth;
    return obj;
  }, {});

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    token.id = Number(token.id);
    const extraInfo = token.metadata
      ? token.metadata
      : await loadBullTraits(token.uri);
    const bullData = bullsData.find((entry) => entry.id == token.id);
    // let claimableMeth = bullData["claimableMeth"];
    let claimableMeth = claimableMethInfo[token.id];
    let breedingsLeft = bullData["breedings"];
    extraInfo.isGodBull = bullData["isGod"];
    token.claimableMeth = Number(claimableMeth);
    token.breedingsLeft = Number(breedingsLeft);
    extraInfo.traits["Breedings Left"] = Number(breedingsLeft);
    extraInfo.type = TokenType.GENESIS;
    // Creator is ID 2758 and should not have breedings left
    if(extraInfo.isCreator){
      delete extraInfo.breedingsLeft;
      delete extraInfo.traits["Breedings Left"];
    }

    // Ensure godBulls don't have breedings left
    if (extraInfo.isGodBull) {
      delete extraInfo.breedingsLeft;
      delete extraInfo.traits["Breedings Left"];
      extraInfo.godPower = godPower[token.id];
      const nowDate = new Date();
      const openUntil = new Date(bullData["lastChestOpen"] * 1000);
      openUntil.setMinutes(openUntil.getMinutes() + 3);
      const prices = [600, 1200, 1800, 2400];
      const chestOpenCount = bullData.lastChestOpenedCount;
      extraInfo.godBox = {
        canOpenAgain:
          chestOpenCount < 4 && openUntil.getTime() > nowDate.getTime(),
        openUntil,
        price: prices[chestOpenCount],
        chestOpenCount: chestOpenCount,
      };

      extraInfo.type = TokenType.GOD;
    }

    // Merge attributes to build the final token meta
    tokens[i] = {
      ...token,
      ...extraInfo,
      isRefundable: false,
    };
  }

  const genesisDiff = Number(bullCount) - tokens.length;
  if (genesisDiff > 0) {
    // Fill the missing tokens with a placeholder
    for (let i = 0; i < genesisDiff; i++) {
      const placeHolderToken = {
        breedingsLeft: 3,
        claimableMeth: 0,
        guild: null,
        id: 0,
        image: require("@/assets/img/Bulls/bull-placeholder.gif"),
        isGodBull: false,
        traits: {},
        type: TokenType.GENESIS,
        isRefundable: false,
      };
      tokens.push(placeHolderToken);
    }
  }

  tokens.sort((a, b) => {
    return b.id - a.id;
  });
  const genesis = tokens.filter((token) => !token.isGodBull);
  const gods = tokens.filter((token) => token.isGodBull);
  return { genesis, gods };
};

function loadBullTraits(metaUri) {
  return new Promise((resolve, reject) => {
    return axios
      .get(metaUri)
      .then((r) => r.data)
      .then((json) => {
        const traits = {};
        let guild = null;
        let isGodBull = false;
        if (!json.attributes) {
          json.attributes = [];
        }
        json.attributes.forEach((attr) => {
          if (attr.trait_type == "Type") {
            isGodBull = attr.value == "God Bull";
          } else if (attr.trait_type == "Guild") {
            guild = attr.value;
          } else if (attr.trait_type != "Traits") {
            traits[attr.trait_type] = attr.value;
          }
        });

        resolve({
          type: TokenType.GENESIS,
          image: json.image ? json.image : json.image_url,
          traits,
          guild,
          isGodBull,
        });
      })
      .catch(reject);
  });
}
