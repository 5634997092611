import mitt from "mitt";
const bus = mitt()
export const useModal = () => {
  const open = (opts) => {
    bus.emit('open', opts)
  }
  const close = (name) => {
    bus.emit('close', name)
  }
  return { bus, open, close}
}
