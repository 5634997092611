import { errorHandler } from "../../helpers/errorHandler";
import { useToast } from "../useToast/useToast";
import { ref } from "vue";
import instance from "./useApiInstance";
import { captureError } from "@/helpers/captureError";

const { show: toastShow } = useToast();
export const _useApi = (construct, opts) => {
  const defaultOptions = { log: false };
  const options = Object.assign({}, defaultOptions, opts);
  const data = ref();
  const error = ref();
  const isLoading = ref(false);
  const exec = async (args) => {
    const requestConfig = construct(args || {});
    try {
      isLoading.value = true;
      const response = await instance(requestConfig);
      data.value = response.data;
      if (options.toast?.success) {
        toastShow({ type: "success", text: options.toast.success(data.value) });
      }
    } catch (err) {
      error.value = err;
      if (options.toast?.error) {
        if (typeof options.toast.error === "boolean") {
          toastShow({ type: "error", text: errorHandler(err) });
        } else {
          toastShow({ type: "error", text: options.toast.error(err) });
        }
      }
      if (options?.log) {
        captureError(err);
      }
      if (options?.throw) {
        throw err;
      }
    } finally {
      isLoading.value = false;
    }
    return data.value;
  };
  return { exec, data, error, isLoading };
};
