<template>
  <transition name="t-scroll-top">
    <div
      v-if="active"
      class="scroll-top-arrow"
      @click.prevent="scrollTop"
    >
      <IArrowUp class="scroll-top-arrow-icon" />
    </div>
  </transition>
</template>
<script>
import IArrowUp from "../assets/icons/arrow-up.svg?inline";
export default {
  components: { IArrowUp },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 30) {
        this.active = true;
      } else {
        this.active = false;
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
